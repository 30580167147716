import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  ContainerSmall,
  ContentFrame,
  ContentFrameSmall,
  H2,
  Regular,
} from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { updateCode, updateEmail } from 'state/user/effects';
import { AppState } from 'state/types';
import { EmailForm } from 'components';
import { useRouter } from 'apis/history';
import { Api } from 'apis/api';
import { handleError } from 'utils/error';

const Email = () => {
  const { goToCheckout } = useRouter();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useQuery();
  const { quiz_answers: answers } = useSelector((s: AppState) => s.user);

  const handleEmailFormSubmit = async (email: string) => {
    const data = {
      email: email,
      funnel: 'main',
      quiz_answers: {
        email: email,
        ...answers,
      },
    };

    try {
      setIsLoading(true);
      const res = await Api.createLead(data);
      const { email = '', code = '' } = res || {};

      dispatch(updateEmail(email));
      dispatch(updateCode(code));

      goToCheckout();
    } catch (e) {
      setIsLoading(false);
      handleError(e);
      setErrorMsg(e?.response?.data?.errors?.email?.[0]);
    }
  };

  return (
    <Wrapper>
      <ContainerSmall>
        <ContentFrame>
          <H2 margin="0 0 1.5rem" textAlign={!isMobile && 'center'}>
            Your custom ZeroSmokes program is just one click away
          </H2>
        </ContentFrame>
        <ContentFrameSmall>
          <Regular margin="0 0 1rem">
            Enter your email address to get access:
          </Regular>
          <EmailForm
            onSubmit={handleEmailFormSubmit}
            errorMessage={errorMsg}
            isLoading={isLoading}
            setErrorMsg={setErrorMsg}
          />
        </ContentFrameSmall>
      </ContainerSmall>
    </Wrapper>
  );
};

export default Email;

const Wrapper = styled.div`
  padding: 3rem 0;
  width: 100%;
  min-height: 82vh;

  @media ${mobile} {
    padding: 1rem 0;
  }
`;
